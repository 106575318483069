import React, { useState } from 'react';
import {
  CollapsableSection,
  InlineFormLabel,
  Checkbox,
  RadioButtonGroup,
  Input,
  CodeEditor,
  DataSourceHttpSettings,
} from '@grafana/ui';
import { DataSourcePluginOptionsEditorProps } from '@grafana/data';
import { selectors } from '../selectors';
import { MockConfig, MockSecureConfig, CustomHealthCheck } from '../types';

type MockConfigEditorProps = {} & DataSourcePluginOptionsEditorProps<MockConfig, MockSecureConfig>;

export const MockConfigEditor = (props: MockConfigEditorProps) => {
  const { options, onOptionsChange } = props;
  const { jsonData = {} } = options;
  const { ConfigEditor: selector } = selectors;
  const onOptionChange = <Key extends keyof MockConfig, Value extends MockConfig[Key]>(option: Key, value: Value) => {
    onOptionsChange({ ...options, jsonData: { ...jsonData, [option]: value } });
  };
  return (
    <>
      <DataSourceHttpSettings defaultUrl={''} dataSourceConfig={options} onChange={onOptionsChange} />
      <CollapsableSection isOpen={true} label={selector.customHealthCheck.title}>
        <div className="gf-form">
          <InlineFormLabel width={14} tooltip={selector.customHealthCheck.enable.tooltip}>
            {selector.customHealthCheck.enable.label}
          </InlineFormLabel>
          <div style={{ padding: '5px' }}>
            <Checkbox
              value={jsonData.customHealthCheckEnabled || false}
              onChange={(e) => onOptionChange('customHealthCheckEnabled', e.currentTarget.checked)}
            />
          </div>
        </div>
        {jsonData.customHealthCheckEnabled && (
          <CustomHealthCheckOptionsEditor
            value={jsonData.customHealthCheck || { status: 0 }}
            onChange={(e) => onOptionChange('customHealthCheck', e)}
          />
        )}
      </CollapsableSection>
    </>
  );
};

const CustomHealthCheckOptionsEditor = (props: {
  value: CustomHealthCheck;
  onChange: (value: CustomHealthCheck) => void;
}) => {
  const { value, onChange } = props;
  const { customHealthCheck: selector } = selectors.ConfigEditor;
  const [message, setMessage] = useState<string>(value.message || '');
  const onOptionChange = <Key extends keyof CustomHealthCheck, Value extends CustomHealthCheck[Key]>(
    k: Key,
    v: Value
  ) => {
    onChange({ ...value, [k]: v });
  };
  return (
    <>
      <div className="gf-form">
        <InlineFormLabel width={14} tooltip={selector.status.tooltip}>
          {selector.status.label}
        </InlineFormLabel>
        <RadioButtonGroup<number>
          options={[
            { value: 1, label: 'OK' },
            { value: 2, label: 'ERROR' },
            { value: 0, label: 'UNKNOWN' },
          ]}
          value={value.status || 0}
          onChange={(e) => onOptionChange('status', e)}
        />
      </div>
      <div className="gf-form">
        <InlineFormLabel width={14} tooltip={selector.message.tooltip}>
          {selector.message.label}
        </InlineFormLabel>
        <Input
          value={message}
          onChange={(e) => setMessage(e.currentTarget.value)}
          onBlur={() => onOptionChange('message', message)}
        />
      </div>
      <div className="gf-form">
        <InlineFormLabel width={14} tooltip={selector.detail.tooltip}>
          {selector.detail.label}
        </InlineFormLabel>
        <CodeEditor
          language="json"
          width={'800px'}
          height={'100px'}
          showLineNumbers={false}
          showMiniMap={false}
          value={value.details || ''}
          onSave={(e) => onOptionChange('details', e)}
          onBlur={(e) => onOptionChange('details', e)}
        />
      </div>
      <div className="gf-form">
        <InlineFormLabel width={14} tooltip={selector.skipBackend.tooltip}>
          {selector.skipBackend.label}
        </InlineFormLabel>
        <div style={{ padding: '5px' }}>
          <Checkbox
            value={value.skipBackend || false}
            onChange={(e) => onOptionChange('skipBackend', e.currentTarget.checked)}
          />
        </div>
      </div>
    </>
  );
};
