import { Observable, of, merge } from 'rxjs';
import { DataSourceWithBackend } from '@grafana/runtime';
import { interpolateRequest, migrateQuery } from './interpolate';
import { rawFrameInlineQuery, rawFrameURLQuery, rawFrameScenarioQuery } from './app/rawFrameQuery';
import { inlineJsonToFrame } from './app/jsonFramer';
import type { DataQueryRequest, DataQueryResponse, DataSourceInstanceSettings, MetricFindValue } from '@grafana/data';
import type {
  MockConfig,
  MockQuery,
  MockVariableQuery,
  GetResourceQuery,
  GetResourceStaticScenarioContent,
  GetResourceStaticScenariosList,
  PostResourceQuery,
  PostResourceQueryProxy,
} from './types';

export class MockDS extends DataSourceWithBackend<MockQuery, MockConfig> {
  constructor(private instanceSettings: DataSourceInstanceSettings<MockConfig>) {
    super(instanceSettings);
    this.annotations = {};
  }
  testDatasource(): Promise<any> {
    const { jsonData } = this.instanceSettings;
    if (jsonData.customHealthCheckEnabled && jsonData.customHealthCheck?.skipBackend) {
      const result: Record<string, any> = {};
      result.message = jsonData.customHealthCheck.message || '';
      switch (jsonData.customHealthCheck.status) {
        case 1:
          result.status = 'success';
          break;
        case 2:
          result.status = 'error';
          break;
        case 0:
        default:
          result.status = 'unknown';
          break;
      }
      if (jsonData.customHealthCheck.details) {
        try {
          const details = JSON.parse(jsonData.customHealthCheck.details);
          result.details = details;
        } catch (ex) {
          console.log(ex);
          result.status = 'error';
          result.message = 'invalid details json provided. Fix the custom details json message';
        }
      }
      return Promise.resolve(result);
    }
    return super.testDatasource();
  }
  query(request: DataQueryRequest<MockQuery>) {
    const updatedRequest = interpolateRequest(request);
    const backendQueries: MockQuery[] = [];
    const streams: Array<Observable<DataQueryResponse>> = [];
    const targets = updatedRequest.targets.map((t) => migrateQuery(t));

    for (const target of targets) {
      if (target.hide) {
        continue;
      } else if (target.queryType === 'json_framer' && target.framer === 'frontend-grafana-data') {
        streams.push(inlineJsonToFrame(target, updatedRequest));
      } else if (
        target.queryType === 'raw_frame' &&
        target.rawFrameSource === 'inline' &&
        target.generatorSource === 'frontend'
      ) {
        streams.push(rawFrameInlineQuery(target, updatedRequest));
      } else if (
        target.queryType === 'raw_frame' &&
        target.rawFrameSource === 'url' &&
        target.generatorSource === 'frontend'
      ) {
        streams.push(rawFrameURLQuery(target, updatedRequest, this.proxyURL));
      } else if (
        target.queryType === 'raw_frame' &&
        target.rawFrameSource === 'staticScenario' &&
        target.generatorSource === 'frontend'
      ) {
        streams.push(rawFrameScenarioQuery(target, updatedRequest, this.getStaticScenarioContent));
      } else {
        backendQueries.push(target);
      }
    }
    if (backendQueries.length) {
      streams.push(super.query({ ...updatedRequest, targets: backendQueries }));
    }
    if (streams.length === 0) {
      return of({ data: [] });
    }
    return merge(...streams);
  }
  async metricFindQuery(query: MockVariableQuery): Promise<MetricFindValue[]> {
    try {
      switch (query.queryType) {
        case 'raw-frame-result-states':
          return [
            { value: 'success', text: 'Success' },
            { value: 'error', text: 'Error' },
            { value: 'error-with-result', text: 'Error with data' },
            { value: 'exception', text: 'Exception' },
          ];
        case 'raw-frame-static-scenarios':
        default:
          const scenarios = await this.getStaticScenarios();
          return scenarios.map((s) => ({ value: s.scenarioId, text: s.name, label: s.name }));
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }
  postResourceLocal = <T extends PostResourceQuery>(path: T['path'], body?: T['body']): Promise<T['response']> => {
    return super.postResource(path, body);
  };
  proxyURL = (url: string, method = 'GET', body = ''): Promise<any> => {
    if (!url) {
      return Promise.reject('invalid url');
    }
    return this.postResourceLocal<PostResourceQueryProxy>('proxy', { url, method, body });
  };
  getResourceLocal = <T extends GetResourceQuery>(path: T['path'], params: T['params']): Promise<T['response']> => {
    return super.getResource(path, params);
  };
  getStaticScenarios = (): Promise<
    Array<{
      name: string;
      scenarioId: string;
      description: string;
    }>
  > => {
    return this.getResourceLocal<GetResourceStaticScenariosList>('static-scenarios', {});
  };
  getStaticScenarioContent = (scenario = 'no-data'): Promise<unknown> => {
    return this.getResourceLocal<GetResourceStaticScenarioContent>('static-scenario-content', { scenario });
  };
}
