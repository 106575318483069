import React, { useState } from 'react';
import { DataFrameType, FieldColorModeId } from '@grafana/data';
import { Button, InlineFormLabel, Input, Select, Cascader } from '@grafana/ui';
import { FrameMetaEditor } from './FrameMetaEditor';
import { FieldConfigEditor } from './FieldMetaEditor';
import type { BuilderQuery, BuilderFrameSource, BuilderFrame } from './../../types';

type BuilderEditorProps = {
  query: BuilderQuery;
  onChange: (query: BuilderQuery) => void;
  onRunQuery: () => void;
};

export const BuilderEditor = (props: BuilderEditorProps) => {
  const { query, onChange, onRunQuery } = props;
  return (
    <>
      {(query.frames || []).length > 0 &&
        query.frames?.map((frame, frameIndex) => {
          return (
            <div key={frameIndex}>
              <div className="gf-form">
                <span style={{ borderLeft: '2px solid blue' }}>
                  <InlineFormLabel width={12}>Frame Name</InlineFormLabel>
                </span>
                <Input
                  placeholder="Frame Name"
                  type="text"
                  value={frame.name}
                  onChange={(e) => {
                    onChange({
                      ...query,
                      frames: query.frames?.map((f, i) => {
                        if (i === frameIndex) {
                          f.name = e.currentTarget.value;
                        }
                        return f;
                      }),
                    });
                    onRunQuery();
                  }}
                />
                <InlineFormLabel width={6}>Frame Size</InlineFormLabel>
                <Select<BuilderFrameSource>
                  width={40}
                  value={frame.source || 'count'}
                  options={[
                    { value: 'count', label: 'Static Count' },
                    { value: 'step', label: 'Step (seconds)' },
                  ]}
                  onChange={(e) => {
                    onChange({
                      ...query,
                      frames: query.frames?.map((f, i) => {
                        if (i === frameIndex) {
                          f.source = e.value;
                        }
                        return f;
                      }),
                    });
                    onRunQuery();
                  }}
                />
                <Input
                  value={frame.count}
                  width={30}
                  placeholder="size"
                  type="number"
                  min={0}
                  onChange={(e) => {
                    onChange({
                      ...query,
                      frames: query.frames?.map((f, i) => {
                        if (i === frameIndex) {
                          f.count = e.currentTarget.valueAsNumber;
                        }
                        return f;
                      }),
                    });
                  }}
                  onBlur={onRunQuery}
                />
                <Button
                  size="sm"
                  variant="secondary"
                  style={{ marginInlineStart: '5px', marginBlockStart: '5px' }}
                  icon="plus-square"
                  onClick={() => {
                    onChange({
                      ...query,
                      frames: query.frames?.map((f, i) => {
                        if (i === frameIndex) {
                          f.fields = [...(f.fields || []), { name: '', type: 'string', disabled: false }];
                        }
                        return f;
                      }),
                    });
                    onRunQuery();
                  }}
                >
                  Add field
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  icon={frame.disabled ? 'eye-slash' : 'eye'}
                  style={{ marginInlineStart: '5px', marginBlockStart: '5px' }}
                  title={frame.disabled ? 'Enable Frame' : 'Disable Frame'}
                  onClick={() => {
                    onChange({
                      ...query,
                      frames: query.frames?.map((f, i) => {
                        if (i === frameIndex) {
                          f.disabled = !f.disabled;
                        }
                        return f;
                      }),
                    });
                    onRunQuery();
                  }}
                />
                <FrameMetaEditor
                  frame={frame}
                  index={frameIndex}
                  onChange={(newFrame, index) => {
                    onChange({
                      ...query,
                      frames: query.frames?.map((f, i) => (i === index ? newFrame : f)),
                    });
                  }}
                  onRunQuery={onRunQuery}
                />
                <Button
                  size="sm"
                  variant="destructive"
                  style={{ marginInlineStart: '5px', marginBlockStart: '5px' }}
                  icon="trash-alt"
                  onClick={() => {
                    query.frames?.splice(frameIndex, 1);
                    onChange(query);
                    onRunQuery();
                  }}
                >
                  Delete frame
                </Button>
              </div>
              {frame.fields?.map((field, fieldIndex) => {
                return (
                  <>
                    <div className="gf-form">
                      <Button
                        size="md"
                        variant="secondary"
                        icon="plus-square"
                        style={{ marginInlineEnd: '5px' }}
                        title="Add field"
                        onClick={() => {
                          onChange({
                            ...query,
                            frames: query.frames?.map((f, i) => {
                              if (i === frameIndex) {
                                f.fields = [...(f.fields || []), { name: '', type: 'string', disabled: false }];
                              }
                              return f;
                            }),
                          });
                          onRunQuery();
                        }}
                      />
                      <Input
                        value={field.name || ''}
                        placeholder="Field Name"
                        width={52}
                        onChange={(e) => {
                          onChange({
                            ...query,
                            frames: query.frames?.map((cFrame, cFrameIndex) => {
                              if (cFrameIndex === frameIndex) {
                                cFrame.fields = cFrame.fields?.map((cField, cFieldIndex) => {
                                  if (fieldIndex === cFieldIndex) {
                                    cField.name = e.currentTarget.value;
                                  }
                                  return cField;
                                });
                              }
                              return cFrame;
                            }),
                          });
                        }}
                        onBlur={onRunQuery}
                      />
                      <Select<string>
                        width={60}
                        value={field.type || 'string'}
                        options={[
                          { value: 'timestamp-auto', label: 'Timestamp (Auto)' },
                          { value: 'timestamp', label: 'Timestamp (epoch s)' },
                          { value: 'timestamp-ms', label: 'Timestamp (epoch ms)' },
                          { value: 'string', label: 'String' },
                          { value: 'nullable-string', label: 'String (Nullable)' },
                          { value: 'float64', label: 'Float64' },
                          { value: 'nullable-float64', label: 'Float64 (Nullable)' },
                          { value: 'int64', label: 'Int64' },
                          { value: 'nullable-int64', label: 'Int64 (Nullable)' },
                          { value: 'boolean', label: 'Boolean' },
                          { value: 'nullable-boolean', label: 'Boolean (Nullable)' },
                          { value: 'float64-expression', label: 'Float64 expression' },
                          { value: 'string-expression', label: 'String expression' },
                          { value: 'boolean-expression', label: 'Boolean expression' },
                          { value: 'timestamp-expression', label: 'Timestamp expression' },
                        ]}
                        onChange={(e) => {
                          onChange({
                            ...query,
                            frames: query.frames?.map((cFrame, cFrameIndex) => {
                              if (cFrameIndex === frameIndex) {
                                cFrame.fields = cFrame.fields?.map((cField, cFieldIndex) => {
                                  if (fieldIndex === cFieldIndex) {
                                    cField.type = e.value;
                                  }
                                  return cField;
                                });
                              }
                              return cFrame;
                            }),
                          });
                          onRunQuery();
                        }}
                      />
                      <Input
                        value={field.type === 'timestamp-auto' ? 'Auto generated values' : field.value || ''}
                        placeholder="Field Values (Comma separated values)"
                        disabled={field.type === 'timestamp-auto'}
                        onChange={(e) => {
                          onChange({
                            ...query,
                            frames: query.frames?.map((cFrame, cFrameIndex) => {
                              if (cFrameIndex === frameIndex) {
                                cFrame.fields = cFrame.fields?.map((cField, cFieldIndex) => {
                                  if (fieldIndex === cFieldIndex) {
                                    cField.value = e.currentTarget.value;
                                  }
                                  return cField;
                                });
                              }
                              return cFrame;
                            }),
                          });
                        }}
                        onBlur={onRunQuery}
                      />
                      <Input
                        value={field.labels || ''}
                        placeholder="Field Labels (Example: foo=bar,micky=mouse)"
                        onChange={(e) => {
                          onChange({
                            ...query,
                            frames: query.frames?.map((cFrame, cFrameIndex) => {
                              if (cFrameIndex === frameIndex) {
                                cFrame.fields = cFrame.fields?.map((cField, cFieldIndex) => {
                                  if (fieldIndex === cFieldIndex) {
                                    cField.labels = e.currentTarget.value;
                                  }
                                  return cField;
                                });
                              }
                              return cFrame;
                            }),
                          });
                          onRunQuery();
                        }}
                      />
                      <Button
                        variant="secondary"
                        size="sm"
                        icon={field.disabled ? 'eye-slash' : 'eye'}
                        style={{ marginInlineStart: '5px', marginBlockStart: '5px' }}
                        title={field.disabled ? 'Enable Field' : 'Disable Field'}
                        onClick={() => {
                          onChange({
                            ...query,
                            frames: query.frames?.map((cFrame, cFrameIndex) => {
                              if (cFrameIndex === frameIndex) {
                                cFrame.fields = cFrame.fields?.map((cField, cFieldIndex) => {
                                  if (fieldIndex === cFieldIndex) {
                                    cField.disabled = !cField.disabled;
                                  }
                                  return cField;
                                });
                              }
                              return cFrame;
                            }),
                          });
                          onRunQuery();
                        }}
                      />
                      <FieldConfigEditor
                        field={field}
                        index={fieldIndex}
                        onChange={(field, index) => {
                          onChange({
                            ...query,
                            frames: query.frames?.map((cFrame, cFrameIndex) => {
                              if (cFrameIndex === frameIndex) {
                                cFrame.fields = cFrame.fields?.map((cField, cFieldIndex) => {
                                  if (index === cFieldIndex) {
                                    return field;
                                  }
                                  return cField;
                                });
                              }
                              return cFrame;
                            }),
                          });
                        }}
                        onRunQuery={onRunQuery}
                      />
                      <Button
                        icon="trash-alt"
                        size="sm"
                        variant="destructive"
                        title="delete field"
                        style={{ marginInlineStart: '5px', marginBlockStart: '5px' }}
                        onClick={(e) => {
                          const newFields = [...(frame.fields || [])];
                          newFields.splice(fieldIndex, 1);
                          onChange({
                            ...query,
                            frames: query.frames?.map((cFrame, cFrameIndex) => {
                              if (cFrameIndex === frameIndex) {
                                cFrame.fields = newFields;
                              }
                              return cFrame;
                            }),
                          });
                          onRunQuery();
                        }}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          );
        })}
      <NewFrameCreator {...props} />
    </>
  );
};

export const NewFrameCreator = (props: BuilderEditorProps) => {
  const { query, onChange, onRunQuery } = props;
  const [newFrameType, setNewFrameType] = useState('timeseries');
  const newFrameOptions = [
    {
      value: 'empty-frame-kind',
      label: 'Empty frame',
      items: [
        { value: 'empty-frame', label: 'Empty frame' },
        {
          value: 'append-empty-frame',
          label: 'Empty frame (append)',
        },
      ],
    },
    {
      value: 'numeric-kind',
      label: 'Numeric',
      items: [
        { value: 'numeric', label: 'Numeric' },
        { value: 'numeric-wide', label: 'Numeric Wide' },
        { value: 'numeric-many', label: 'Numeric Many' },
        { value: 'numeric-long', label: 'Numeric Long' },
      ],
    },
    {
      value: 'timeseries-kind',
      label: 'Timeseries',
      items: [
        { value: 'timeseries', label: 'Timeseries' },
        { value: 'timeseries-wide', label: 'Timeseries Wide' },
        { value: 'timeseries-multi', label: 'Timeseries Multi' },
        { value: 'timeseries-long', label: 'Timeseries Long' },
      ],
    },
    {
      value: 'table-kind',
      label: 'Table',
      items: [{ value: 'table', label: 'Table' }],
    },
    {
      value: 'histogram-kind',
      label: 'Histogram',
      items: [
        { value: 'histogram', label: 'Histogram' },
        { value: 'histogram-wide', label: 'Histogram Wide' },
        { value: 'histogram-multi', label: 'Histogram Multi' },
      ],
    },
    {
      value: 'logs-kind',
      label: 'Logs',
      items: [{ value: 'logs', label: 'Logs' }],
    },
    {
      value: 'node-graph-kind',
      label: 'Node Graph',
      items: [{ value: 'node-graph', label: 'Nodes Graph' }],
    },
  ];
  const addFrame = () => {
    let newFrames: BuilderFrame[] = [];
    switch (newFrameType) {
      case 'empty-frame':
      case 'append-empty-frame':
        newFrames = [
          {
            name: 'New Frame',
            source: 'count',
            count: 1,
            disabled: false,
            fields: [],
          },
        ];
        break;
      case 'numeric':
        newFrames = [
          {
            name: 'New Frame',
            source: 'count',
            count: 1,
            disabled: false,
            fields: [{ name: 'cpu', type: 'nullable-float64', value: '3.14', disabled: false }],
          },
        ];
        break;
      case 'numeric-wide':
        newFrames = [
          {
            name: 'New Frame',
            source: 'count',
            count: 1,
            disabled: false,
            fields: [
              { name: 'cpu', type: 'nullable-float64', value: '3.14', disabled: false, labels: 'host=a' },
              { name: 'cpu', type: 'nullable-float64', value: '93.14', disabled: false, labels: 'host=b' },
            ],
          },
        ];
        break;
      case 'numeric-many':
        newFrames = [
          {
            name: 'New Frame',
            source: 'count',
            count: 1,
            disabled: false,
            fields: [{ name: 'cpu', type: 'nullable-float64', value: '3.14', disabled: false, labels: 'host=a' }],
          },
          {
            name: 'New Frame',
            source: 'count',
            count: 1,
            disabled: false,
            fields: [{ name: 'cpu', type: 'nullable-float64', value: '93.14', disabled: false, labels: 'host=b' }],
          },
        ];
        break;
      case 'numeric-long':
        newFrames = [
          {
            name: 'New Frame',
            source: 'count',
            count: 2,
            disabled: false,
            fields: [
              { name: 'host', type: 'nullable-string', value: 'a,b', disabled: false, labels: '' },
              { name: 'cpu', type: 'nullable-float64', value: '3.14,93.14', disabled: false, labels: '' },
            ],
          },
        ];
        break;
      case 'timeseries-wide':
        newFrames = [
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 60,
            fields: [
              { name: 'timestamp', type: 'timestamp-auto', disabled: false },
              { name: 'cpu', type: 'nullable-float64', value: '1,4,2,3', disabled: false, labels: 'host=a' },
              { name: 'cpu', type: 'nullable-float64', value: '6,8,5,9', disabled: false, labels: 'host=b' },
            ],
            meta: {
              type: DataFrameType.TimeSeriesWide,
            },
          },
        ];
        break;
      case 'timeseries-multi':
        newFrames = [
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 60,
            fields: [
              { name: 'timestamp', type: 'timestamp-auto', disabled: false },
              { name: 'cpu', type: 'nullable-float64', value: '1,4,2,3', disabled: false, labels: 'host=a' },
            ],
            meta: {
              type: DataFrameType.TimeSeriesMany,
            },
          },
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 60,
            fields: [
              { name: 'timestamp', type: 'timestamp-auto', disabled: false },
              { name: 'cpu', type: 'nullable-float64', value: '6,8,5,9', disabled: false, labels: 'host=b' },
            ],
            meta: {
              type: DataFrameType.TimeSeriesMany,
            },
          },
        ];
        break;
      case 'timeseries-long':
        newFrames = [
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 60,
            fields: [
              { name: 'timestamp', type: 'timestamp-auto', disabled: false },
              { name: 'value', type: 'nullable-float64', value: '1,2,3,2', disabled: false },
            ],
            meta: {
              type: DataFrameType.TimeSeriesLong,
            },
          },
        ];
        break;
      case 'table':
        newFrames = [
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 3,
            fields: [
              { name: 'username', type: 'nullable-string', value: 'john,doe,alice', disabled: false, labels: '' },
              { name: 'height', type: 'nullable-float64', value: '4,1,6', disabled: false, labels: '' },
              { name: 'role', type: 'nullable-string', value: 'ceo,dev,dev', disabled: false, labels: '' },
              { name: 'gender', type: 'nullable-string', value: 'm,null,f', disabled: false, labels: '' },
              { name: 'isPremium', type: 'nullable-boolean', value: 'false,true,false', disabled: false, labels: '' },
              {
                name: 'dob',
                type: 'timestamp',
                value: '327110400,569635200,1293840000',
                disabled: false,
                labels: '',
              },
            ],
          },
        ];
        break;
      case 'logs':
        newFrames = [
          {
            name: 'NewFrame',
            source: 'step',
            disabled: false,
            count: 60,
            meta: {
              preferredVisualisationType: 'logs',
            },
            fields: [
              {
                name: 'timestamp',
                type: 'timestamp-expression',
                value: 'from + (i * 1000 * 60 ) + ( i * 8000 )',
                disabled: false,
                labels: '',
              },
              {
                name: 'message',
                type: 'string-expression',
                value: 'csv("foo","bar","baz",  i * random() * 100)',
                disabled: false,
                labels: '',
              },
              {
                name: 'level',
                type: 'nullable-string',
                value: 'info,,,,error,info,warn',
                disabled: false,
                labels: '',
              },
            ],
          },
        ];
        break;
      case 'node-graph':
        newFrames = [
          {
            name: 'Nodes',
            disabled: false,
            source: 'count',
            meta: { preferredVisualisationType: 'nodeGraph' },
            count: 4,
            fields: [
              { name: 'id', type: 'string', value: 'A,B,C,D', disabled: false },
              { name: 'title', type: 'string', value: 'Server A,Server B,Server C,Server D', disabled: false },
              {
                name: 'subTitle',
                type: 'string',
                value: 'Application Server,DB Server,Application Server,Router',
                disabled: false,
              },
              { name: 'mainStat', type: 'float64', value: '12,90,20,47', disabled: false },
              { name: 'secondaryStat', type: 'string', value: '10,87,23,98', disabled: false },
              {
                name: 'arc__cpu',
                type: 'float64',
                value: '0.1,0.2,0.3,0.4',
                disabled: false,
                config: { color: { mode: FieldColorModeId.Fixed, fixedColor: 'red' } },
              },
              {
                name: 'arc__memory',
                type: 'float64',
                value: '0.9,0.8,0.7,0.6',
                disabled: false,
                config: { color: { mode: FieldColorModeId.Fixed, fixedColor: 'blue' } },
              },
            ],
          },
          {
            name: 'Edges',
            disabled: false,
            source: 'count',
            meta: { preferredVisualisationType: 'nodeGraph' },
            count: 3,
            fields: [
              { name: 'id', type: 'string', value: '1,2,3', disabled: false },
              { name: 'source', type: 'string', value: 'A,A,B', disabled: false },
              { name: 'target', type: 'string', value: 'B,C,D', disabled: false },
              { name: 'mainStat', type: 'string', value: 'm1,m2,m3', disabled: false },
              { name: 'secondaryStat', type: 'string', value: 's1,s2,s3', disabled: false },
              { name: 'detail_one', type: 'string', value: 'd1,d2,d3', disabled: false },
            ],
          },
        ];
        break;
      case 'numeric-wide':
        newFrames = [
          {
            name: 'New Frame',
            source: 'count',
            count: 1,
            disabled: false,
            fields: [
              { name: 'cpu', type: 'nullable-float64', value: '3.14', disabled: false, labels: 'host=a' },
              { name: 'cpu', type: 'nullable-float64', value: '93.14', disabled: false, labels: 'host=b' },
            ],
          },
        ];
        break;
      case 'histogram':
        newFrames = [
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 3,
            fields: [
              {
                name: 'BucketMin',
                type: 'nullable-float64',
                value: '0, 33333.333333333336, 66666.66666666667',
                disabled: false,
                labels: '',
              },
              {
                name: 'BucketMax',
                type: 'nullable-float64',
                value: '33333.333333333336, 66666.66666666667, 100000',
                disabled: false,
                labels: '',
              },
              { name: 'cpu', type: 'nullable-float64', value: '413, 59, 89', disabled: false, labels: '' },
            ],
          },
        ];
        break;
      case 'histogram-wide':
        newFrames = [
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 3,
            fields: [
              {
                name: 'BucketMin',
                type: 'nullable-float64',
                value: '0, 33333.333333333336, 66666.66666666667',
                disabled: false,
                labels: '',
              },
              {
                name: 'BucketMax',
                type: 'nullable-float64',
                value: '33333.333333333336, 66666.66666666667, 100000',
                disabled: false,
                labels: '',
              },
              { name: 'cpu', type: 'nullable-float64', value: '413, 59, 89', disabled: false, labels: 'host=a' },
              { name: 'cpu', type: 'nullable-float64', value: '20, 39, 40', disabled: false, labels: 'host=b' },
            ],
          },
        ];
        break;
      case 'histogram-multi':
        newFrames = [
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 3,
            fields: [
              {
                name: 'BucketMin',
                type: 'nullable-float64',
                value: '0, 33333.333333333336, 66666.66666666667',
                disabled: false,
                labels: '',
              },
              {
                name: 'BucketMax',
                type: 'nullable-float64',
                value: '33333.333333333336, 66666.66666666667, 100000',
                disabled: false,
                labels: '',
              },
              { name: 'cpu', type: 'nullable-float64', value: '413, 59, 89', disabled: false, labels: 'host=a' },
            ],
          },
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 3,
            fields: [
              {
                name: 'BucketMin',
                type: 'nullable-float64',
                value: '0, 33333.333333333336, 66666.66666666667',
                disabled: false,
                labels: '',
              },
              {
                name: 'BucketMax',
                type: 'nullable-float64',
                value: '33333.333333333336, 66666.66666666667, 100000',
                disabled: false,
                labels: '',
              },
              { name: 'cpu', type: 'nullable-float64', value: '20, 39, 40', disabled: false, labels: 'host=b' },
            ],
          },
        ];
        break;
      case 'timeseries':
      default:
        newFrames = [
          {
            name: `New Frame`,
            source: 'count',
            disabled: false,
            count: 60,
            fields: [
              { name: 'timestamp', type: 'timestamp-auto', disabled: false },
              { name: 'value', type: 'nullable-float64', value: '1,2,3,2', disabled: false },
            ],
          },
        ];
        break;
    }
    if (newFrameType === 'append-empty-frame') {
      onChange({
        ...query,
        frames: [
          ...(query.frames || []),
          ...newFrames.map((f) => {
            return { ...f, frameInputType: newFrameType };
          }),
        ],
      });
    } else {
      onChange({
        ...query,
        frames: [
          ...newFrames.map((f) => {
            return { ...f, frameInputType: newFrameType };
          }),
        ],
      });
    }
    onRunQuery();
  };
  return (
    <div className="gf-form">
      <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
        <Button variant="secondary" icon="plus-square" style={{ marginInlineStart: '5px' }} onClick={addFrame}>
          {(query.frames || [])?.length === 0 ? 'Add Frame' : 'Switch Frame Type'}
        </Button>
        <Cascader
          initialValue={newFrameType || 'timeseries'}
          options={newFrameOptions}
          changeOnSelect={false}
          onSelect={(e) => setNewFrameType(e)}
          width={30}
        />
      </div>
    </div>
  );
};
