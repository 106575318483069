import React from 'react';
import { InlineFormLabel, RadioButtonGroup } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { selectors } from './../../selectors';
import { MockQuery, MockQueryType } from './../../types';

const queryTypes: Array<SelectableValue<MockQueryType>> = [
  { value: 'frames_builder', label: 'Frames Builder' },
  { value: 'raw_frame', label: 'Raw Frame' },
  { value: 'json_framer', label: 'Any JSON to Data Frame' },
];

export const QueryTypePicker = (props: {
  query: MockQuery;
  onChange: (query: MockQuery) => void;
  onRunQuery: () => void;
}) => {
  const { query, onChange, onRunQuery } = props;
  const { queryTypePicker: selector } = selectors.QueryEditor;
  return (
    <>
      <div className="gf-form">
        <InlineFormLabel width={12} tooltip={selector.tooltip}>
          {selector.label}
        </InlineFormLabel>
        <RadioButtonGroup<MockQueryType>
          value={query.queryType || 'frames_builder'}
          options={queryTypes}
          onChange={(e) => {
            onChange({ ...query, queryType: e } as MockQuery);
            onRunQuery();
          }}
        />
      </div>
    </>
  );
};
