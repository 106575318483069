import { Observable, of, delay } from 'rxjs';
import { DataQueryRequest, DataQueryResponse, toDataFrame } from '@grafana/data';
import { JsonFramerQuery, MockQuery } from './../types';

export const inlineJsonToFrame = (
  target: Extract<JsonFramerQuery, { framer: 'frontend-grafana-data' }>,
  options: DataQueryRequest<MockQuery>
): Observable<DataQueryResponse> => {
  try {
    if (!target.jsonString) {
      return of({ data: [], error: new Error('invalid json') });
    }
    const inputJSON = JSON.parse(target.jsonString || '');
    const frame = toDataFrame(inputJSON);
    return of({ data: [frame] }).pipe(delay(100));
  } catch (ex: any) {
    return of({ data: [], error: ex });
  }
};
