import { InlineFormLabel, Select } from '@grafana/ui';
import React from 'react';
import { MockDS } from '../datasource';
import { MockVariableQuery, MockVariableQueryType } from '../types';

type MockVariableEditorProps = {
  datasource: MockDS;
  query: MockVariableQuery;
  onChange: (query: MockVariableQuery, definition: string) => void;
};

export const MockVariableEditor = (props: MockVariableEditorProps) => {
  const { query, onChange } = props;
  return (
    <>
      <div className="gf-form">
        <InlineFormLabel>QueryType</InlineFormLabel>
        <Select<MockVariableQueryType>
          value={query.queryType || 'raw-frame-static-scenarios'}
          options={[
            { value: 'raw-frame-static-scenarios', label: 'Raw frame Scenarios' },
            { value: 'raw-frame-result-states', label: 'Raw frame Result States' },
          ]}
          onChange={(e) => {
            onChange(
              { ...query, queryType: e.value || 'raw-frame-static-scenarios' } as MockVariableQuery,
              e.value || 'raw-frame-static-scenarios'
            );
          }}
        ></Select>
      </div>
    </>
  );
};
