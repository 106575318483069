import React, { useState, useEffect } from 'react';
import { InlineFormLabel, RadioButtonGroup, CodeEditor, Input, Select } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { MockDS } from './../../datasource';
import { selectors } from './../../selectors';
import {
  MockQuery,
  RawFrameSource,
  RawFrameQuery,
  InlineRawFrameQuery,
  URLRawFrameQuery,
  StaticScenarioRawFrameQuery,
  RawFrameResultState,
  RawFrameGeneratorSource,
} from './../../types';

const modes: Array<SelectableValue<RawFrameSource>> = [
  { value: 'inline', label: 'Inline' },
  { value: 'url', label: 'URL' },
  { value: 'staticScenario', label: 'Predefined Frames / Scenarios' },
];

const resultStates: Array<SelectableValue<RawFrameResultState>> = [
  { value: 'success', label: 'Success' },
  { value: 'error-with-result', label: 'Error with data' },
  { value: 'error', label: 'Error without data' },
  { value: 'exception', label: 'Exception / Panic' },
];

const generatorSources: Array<SelectableValue<RawFrameGeneratorSource>> = [
  { value: 'backend', label: 'Backend' },
  { value: 'frontend', label: 'Frontend' },
];

const { rawFramesEditor: selector } = selectors.QueryEditor;

export const RawFrameEditor = (props: {
  query: RawFrameQuery;
  onChange: (query: MockQuery) => void;
  onRunQuery: () => void;
  datasource: MockDS;
}) => {
  const { query, onChange, onRunQuery } = props;
  const [errorMessage, setErrorMessage] = useState(query.errorMessage || '');
  const variables = getTemplateSrv()
    .getVariables()
    .map((v) => ({ value: `$${v.name}`, label: 'var: ' + v.name }));
  return (
    <>
      <div className="gf-form">
        <InlineFormLabel width={12} tooltip={selector.mode.tooltip}>
          {selector.mode.label}
        </InlineFormLabel>
        <RadioButtonGroup<RawFrameSource>
          value={query.rawFrameSource || 'inline'}
          options={modes}
          onChange={(e) => {
            onChange({ ...query, rawFrameSource: e } as MockQuery);
            onRunQuery();
          }}
        />
      </div>
      {query.rawFrameSource === 'inline' && (
        <RawFrameInlineModeEditor query={query} onChange={onChange} onRunQuery={onRunQuery} />
      )}
      {query.rawFrameSource === 'url' && (
        <RawFrameURLModeEditor query={query} onChange={onChange} onRunQuery={onRunQuery} />
      )}
      {query.rawFrameSource === 'staticScenario' && (
        <RawFrameStaticScenarioModeEditor
          query={query}
          onChange={onChange}
          onRunQuery={onRunQuery}
          datasource={props.datasource}
        />
      )}
      <div className="gf-form">
        <InlineFormLabel width={12} tooltip={selector.resultState.tooltip}>
          {selector.resultState.label}
        </InlineFormLabel>
        <Select<RawFrameResultState>
          value={query.resultState || 'success'}
          options={[...resultStates, ...variables] as Array<SelectableValue<RawFrameResultState>>}
          onChange={(e) => {
            onChange({ ...query, resultState: e.value } as MockQuery);
            onRunQuery();
          }}
        />
        <InlineFormLabel width={8} tooltip={'Status code'}>
          Status code
        </InlineFormLabel>
        <Input
          type="number"
          value={query.resultStatusCode}
          placeholder="200"
          onChange={(e) => {
            if (e.currentTarget.value.trim() !== '') {
              onChange({ ...query, resultStatusCode: e.currentTarget.valueAsNumber });
              onRunQuery();
            } else {
              onChange({ ...query, resultStatusCode: undefined });
              onRunQuery();
            }
          }}
        />
        {query.resultState && query.resultState !== 'success' && (
          <>
            <InlineFormLabel width={12} tooltip={'Custom error message'}>
              Error message
            </InlineFormLabel>
            <Input
              value={errorMessage}
              onChange={(e) => setErrorMessage(e.currentTarget.value)}
              onBlur={() => {
                onChange({ ...query, errorMessage });
                onRunQuery();
              }}
            />
          </>
        )}
      </div>
      <div className="gf-form">
        <InlineFormLabel width={12} tooltip={selector.generatorSource.tooltip}>
          {selector.generatorSource.label}
        </InlineFormLabel>
        <Select<RawFrameGeneratorSource>
          value={query.generatorSource || 'frontend'}
          options={[...generatorSources, ...variables] as Array<SelectableValue<RawFrameGeneratorSource>>}
          onChange={(e) => {
            onChange({ ...query, generatorSource: e.value } as MockQuery);
            onRunQuery();
          }}
        />
      </div>
    </>
  );
};

const RawFrameInlineModeEditor = (props: {
  query: InlineRawFrameQuery;
  onChange: (query: MockQuery) => void;
  onRunQuery: () => void;
}) => {
  const { query, onChange, onRunQuery } = props;
  return (
    <>
      <div className="gf-form">
        <InlineFormLabel width={12} tooltip={selector.rawContent.tooltip}>
          {selector.rawContent.label}
        </InlineFormLabel>
      </div>
      <div>
        <CodeEditor
          language="json"
          height="200px"
          value={query.rawFrameContent || ''}
          onSave={(e) => {
            onChange({ ...query, rawFrameSource: 'inline', rawFrameContent: e });
            onRunQuery();
          }}
          onBlur={(e) => {
            onChange({ ...query, rawFrameSource: 'inline', rawFrameContent: e });
            onRunQuery();
          }}
        />
      </div>
    </>
  );
};

const RawFrameURLModeEditor = (props: {
  query: URLRawFrameQuery;
  onChange: (query: MockQuery) => void;
  onRunQuery: () => void;
}) => {
  const { query, onChange, onRunQuery } = props;
  const [url, setUrl] = useState(query.url || '');
  return (
    <>
      <div className="gf-form">
        <InlineFormLabel width={12} tooltip={selector.url.tooltip}>
          {selector.url.label}
        </InlineFormLabel>
        <Input
          value={url}
          onChange={(e) => setUrl(e.currentTarget.value)}
          onBlur={() => {
            onChange({ ...query, rawFrameSource: 'url', url });
            onRunQuery();
          }}
        />
      </div>
    </>
  );
};

const RawFrameStaticScenarioModeEditor = (props: {
  query: StaticScenarioRawFrameQuery;
  onChange: (query: MockQuery) => void;
  onRunQuery: () => void;
  datasource: MockDS;
}) => {
  const { query, onChange, onRunQuery, datasource } = props;
  const [scenarios, setScenarios] = useState<Array<SelectableValue<string>>>([]);
  useEffect(() => {
    datasource
      .getStaticScenarios()
      .then((res) => {
        const staticScenarios = (res || []).map((r) => {
          return { value: r.scenarioId, label: r.name };
        });
        const variables = getTemplateSrv()
          .getVariables()
          .map((v) => ({ value: `$${v.name}`, label: 'var: ' + v.name }));
        setScenarios([...staticScenarios, ...variables]);
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, [datasource]);

  return (
    <>
      <div className="gf-form">
        <InlineFormLabel width={12} tooltip={selector.staticScenario.tooltip}>
          {selector.staticScenario.label}
        </InlineFormLabel>
        <Select
          value={query.scenario || 'no-data'}
          options={scenarios}
          onChange={(e) => {
            if (e) {
              onChange({ ...query, scenario: e.value } as MockQuery);
              onRunQuery();
            }
          }}
        />
      </div>
    </>
  );
};
