export const selectors = {
  ConfigEditor: {
    customHealthCheck: {
      title: 'Custom HealthCheck',
      enable: {
        label: 'Enable custom health check',
        tooltip: 'allow you to have custom health check messages',
      },
      status: {
        label: 'Custom status',
        tooltip: 'custom health check status',
      },
      message: {
        label: 'Custom message',
        tooltip: 'custom health check message. Leave blank for empty message',
      },
      detail: {
        label: 'Custom detail json',
        tooltip: 'Leave blank to ignore. Format: { "message": "your message", "verboseMessage":"detailed message"}',
      },
      skipBackend: {
        label: 'Skip backend',
        tooltip: 'allow you to skip backend call for health check',
      },
    },
  },
  QueryEditor: {
    queryTypePicker: {
      label: 'Query Type',
      tooltip: 'Select the query type',
    },
    rawFramesEditor: {
      mode: {
        label: 'Mode',
        tooltip: 'Select the mode',
      },
      rawContent: {
        label: 'Raw frame content',
        tooltip: 'Enter the raw frame content. Should be JSON array of frames',
      },
      url: {
        label: 'URL',
        tooltip: 'Enter the URL for the frames json',
      },
      staticScenario: {
        label: 'Scenario',
        tooltip: 'Select the scenario',
      },
      resultState: {
        label: 'Result State',
        tooltip: 'Simulate the failures',
      },
      generatorSource: {
        label: 'Data Generator',
        tooltip: 'allow you to skip backend call for query',
      },
    },
    jsonFramerEditor: {
      framer: {
        label: 'Framer',
        tooltip: 'Framer',
      },
    },
  },
};
