import { Observable, of, delay } from 'rxjs';
import { DataQueryRequest, DataQueryResponse, toDataFrame, LoadingState } from '@grafana/data';
import { RawFrameQuery, MockQuery } from './../types';

export const rawFrameInlineQuery = (
  target: Extract<RawFrameQuery, { rawFrameSource: 'inline' }>,
  options: DataQueryRequest<MockQuery>
): Observable<DataQueryResponse> => {
  const errorMessage = target.errorMessage || 'this error is intentional';
  try {
    let input = JSON.parse(target.rawFrameContent ?? '[]');
    if (typeof input === 'object' && !Array.isArray(input)) {
      input = [input];
    }
    if (typeof input === 'object' && Array.isArray(input)) {
      const data = input.map((v: any) => {
        const f = toDataFrame(v);
        f.refId = target.refId;
        return f;
      });
      if (target.resultState === 'error') {
        return of({
          state: LoadingState.Error,
          error: new Error(errorMessage),
        } as unknown as DataQueryResponse).pipe(delay(100));
      } else if (target.resultState === 'error-with-result') {
        return of({
          data,
          state: LoadingState.Error,
          error: new Error(errorMessage),
        }).pipe(delay(100));
      } else if (target.resultState === 'exception') {
        throw new Error(target.errorMessage || 'this exception is intentional');
      } else {
        return of({ data, state: LoadingState.Done }).pipe(delay(100));
      }
    } else {
      return of({
        data: [],
        state: LoadingState.Error,
        error: new Error('invalid input. Looking for array of data frames'),
      }).pipe(delay(100));
    }
  } catch (ex: any) {
    return of({ data: [], error: ex }).pipe(delay(100));
  }
};

export const rawFrameScenarioQuery = (
  target: Extract<RawFrameQuery, { rawFrameSource: 'staticScenario' }>,
  options: DataQueryRequest<MockQuery>,
  getStaticScenarioContent: (scenario?: string) => Promise<unknown>
): Observable<DataQueryResponse> => {
  const errorMessage = target.errorMessage || 'this error is intentional';
  return new Observable((subscriber) => {
    getStaticScenarioContent(target.scenario || 'no-data')
      .then((res) => {
        if (typeof res === 'object' && Array.isArray(res)) {
          if (target.resultState === 'error') {
            subscriber.next({ error: new Error(errorMessage) } as unknown as DataQueryResponse);
            subscriber.complete();
          } else if (target.resultState === 'error-with-result') {
            subscriber.next({ data: res.map((r) => toDataFrame(r)), error: new Error(errorMessage) });
            subscriber.complete();
          } else if (target.resultState === 'exception') {
            subscriber.error(new Error('this exception is intentional'));
            subscriber.complete();
          } else {
            subscriber.next({ data: res.map((r) => toDataFrame(r)), state: LoadingState.Done });
            subscriber.complete();
          }
        } else {
          subscriber.error(new Error('invalid response received from scenario'));
          subscriber.complete();
        }
      })
      .catch((ex) => {
        subscriber.error(new Error(ex));
        subscriber.complete();
      });
  });
};

export const rawFrameURLQuery = (
  target: Extract<RawFrameQuery, { rawFrameSource: 'url' }>,
  options: DataQueryRequest<MockQuery>,
  proxyURL: (url: string, method?: string, body?: string) => Promise<any>
): Observable<DataQueryResponse> => {
  return new Observable((subscriber) => {
    proxyURL(target.url, 'GET')
      .then((res) => {
        if (typeof res === 'object' && Array.isArray(res)) {
          subscriber.next({ data: res.map((r) => toDataFrame(r)), state: LoadingState.Done });
          subscriber.complete();
        } else if (typeof res === 'object' && !Array.isArray(res)) {
          subscriber.next({ data: [res].map((r) => toDataFrame(r)), state: LoadingState.Done });
          subscriber.complete();
        } else {
          subscriber.error(new Error('invalid response received'));
          subscriber.complete();
        }
      })
      .catch((ex) => {
        subscriber.error(new Error(ex));
        subscriber.complete();
      });
  });
};
