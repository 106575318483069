import React, { useState } from 'react';
import { Button, InlineFormLabel, Input, Drawer, TextArea, Select, ColorPicker } from '@grafana/ui';
import type { BuilderField } from '../../types';
import { FALLBACK_COLOR, FieldColorModeId, FieldColorSeriesByMode } from '@grafana/data';

export const FieldConfigEditor = ({
  field,
  onChange,
  onRunQuery,
  index,
}: {
  field: BuilderField;
  index: number;
  onChange: (field: BuilderField, index: number) => void;
  onRunQuery: () => void;
}) => {
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState(field.config?.description || '');
  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        style={{ marginInlineStart: '5px', marginBlockStart: '5px' }}
        icon="shield-exclamation"
        onClick={() => setShow(!show)}
      >
        Field Meta
      </Button>
      {show && (
        <Drawer
          onClose={() => {
            setShow(false);
            onRunQuery();
          }}
          title="Field Config"
        >
          <div className="gf-form">
            <InlineFormLabel>Name</InlineFormLabel>
            <Input
              value={field.name}
              onChange={(e) => onChange({ ...field, name: e.currentTarget.value }, index)}
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel
              tooltip={'The display value for this field.  This supports template variables blank is auto'}
            >
              DisplayName
            </InlineFormLabel>
            <Input
              value={field.config?.displayName}
              onChange={(e) =>
                onChange({ ...field, config: { ...field.config, displayName: e.currentTarget.value } }, index)
              }
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel
              tooltip={`This can be used by data sources that return and explicit naming structure for values and labels. When this property is configured, this value is used rather than the default naming strategy.`}
            >
              DisplayNameFromDS
            </InlineFormLabel>
            <Input
              value={field.config?.displayNameFromDS}
              onChange={(e) =>
                onChange({ ...field, config: { ...field.config, displayNameFromDS: e.currentTarget.value } }, index)
              }
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Description</InlineFormLabel>
            <TextArea
              value={description}
              rows={5}
              onChange={(e) => setDescription(e.currentTarget.value)}
              placeholder={`Human readable field metadata`}
              onBlur={() => onChange({ ...field, config: { ...field.config, description } }, index)}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Path</InlineFormLabel>
            <Input
              value={field.config?.path}
              onChange={(e) => onChange({ ...field, config: { ...field.config, path: e.currentTarget.value } }, index)}
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Unit</InlineFormLabel>
            <Input
              value={field.config?.unit}
              onChange={(e) => onChange({ ...field, config: { ...field.config, unit: e.currentTarget.value } }, index)}
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Decimals</InlineFormLabel>
            <Input
              type="number"
              value={field.config?.decimals || ''}
              onChange={(e) =>
                onChange({ ...field, config: { ...field.config, decimals: e.currentTarget.valueAsNumber } }, index)
              }
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Min</InlineFormLabel>
            <Input
              type="number"
              value={field.config?.min || ''}
              onChange={(e) =>
                onChange({ ...field, config: { ...field.config, min: e.currentTarget.valueAsNumber } }, index)
              }
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Max</InlineFormLabel>
            <Input
              type="number"
              value={field.config?.max || ''}
              onChange={(e) =>
                onChange({ ...field, config: { ...field.config, max: e.currentTarget.valueAsNumber } }, index)
              }
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel
              tooltip={`Interval (in milliseconds) indicates the expected regular time duration between values in the series. When an interval exists, consumers can identify when values are "missing". The grafana timeseries visualization will render disconnected values when missing values are found in the time field.`}
            >
              Interval
            </InlineFormLabel>
            <Input
              type="number"
              value={field.config?.interval || ''}
              onChange={(e) =>
                onChange({ ...field, config: { ...field.config, interval: e.currentTarget.valueAsNumber } }, index)
              }
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Color Mode</InlineFormLabel>
            <Select
              options={[
                { value: FieldColorModeId.Fixed, label: 'Fixed' },
                { value: FieldColorModeId.Thresholds, label: 'Thresholds' },
                { value: FieldColorModeId.PaletteClassic, label: 'Palette Classic' },
                { value: FieldColorModeId.PaletteSaturated, label: 'Palette Saturated' },
                { value: FieldColorModeId.ContinuousGrYlRd, label: 'Continuous GrYlRd' },
              ]}
              value={field.config?.color?.mode || FieldColorModeId.Fixed}
              onChange={(e) =>
                onChange(
                  {
                    ...field,
                    config: {
                      ...field.config,
                      color: { ...field.config?.color, mode: e.value || FieldColorModeId.Fixed },
                    },
                  },
                  index
                )
              }
              onBlur={onRunQuery}
            />
            <div style={{ padding: '5px' }}>
              <ColorPicker
                color={field.config?.color?.fixedColor || FALLBACK_COLOR}
                onChange={(e) =>
                  onChange(
                    {
                      ...field,
                      config: {
                        ...field.config,
                        color: {
                          ...field.config?.color,
                          mode: field.config?.color?.mode || FieldColorModeId.Fixed,
                          fixedColor: e,
                        },
                      },
                    },
                    index
                  )
                }
              />
            </div>
            <Select<FieldColorSeriesByMode>
              width={20}
              options={[
                { value: 'min', label: 'Min' },
                { value: 'max', label: 'Max' },
                { value: 'last', label: 'Last' },
              ]}
              isClearable={true}
              value={field.config?.color?.seriesBy}
              onChange={(e) => {
                onChange(
                  {
                    ...field,
                    config: {
                      ...field.config,
                      color: {
                        ...field.config?.color,
                        mode: field.config?.color?.mode || FieldColorModeId.Fixed,
                        seriesBy: e && e.value ? e.value : undefined,
                      },
                    },
                  },
                  index
                );
              }}
              onBlur={onRunQuery}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
