import React, { useState } from 'react';
import { Button, InlineFormLabel, Input, Select, Drawer, TextArea } from '@grafana/ui';
import { PreferredVisualisationType, DataFrameType, QueryResultMeta } from '@grafana/data';
import type { BuilderFrame } from './../../types';

export const FrameMetaEditor = ({
  frame,
  onChange,
  onRunQuery,
  index,
}: {
  frame: BuilderFrame;
  index: number;
  onChange: (frame: BuilderFrame, index: number) => void;
  onRunQuery: () => void;
}) => {
  const [show, setShow] = useState(false);
  const [custom, setCustom] = useState(JSON.stringify(frame?.meta?.custom || {}) || '{}');
  const onFrameVersionChange = (type: 'major' | 'minor', value = 0) => {
    const existing = ((frame.meta as any).typeVersion as number[]) || [];
    if (type === 'major') {
      onChange({ ...frame, meta: { ...frame.meta, typeVersion: [value, existing[1] || 0] } as QueryResultMeta }, index);
    }
    if (type === 'minor') {
      onChange({ ...frame, meta: { ...frame.meta, typeVersion: [existing[0] || 0, value] } as QueryResultMeta }, index);
    }
  };
  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        style={{ marginInlineStart: '5px', marginBlockStart: '5px' }}
        icon="shield-exclamation"
        onClick={() => setShow(!show)}
      >
        Frame Meta
      </Button>
      {show && (
        <Drawer
          onClose={() => {
            setShow(false);
            onRunQuery();
          }}
          title="Frame Meta"
        >
          <div className="gf-form">
            <InlineFormLabel>Name</InlineFormLabel>
            <Input
              value={frame.name}
              onChange={(e) => onChange({ ...frame, name: e.currentTarget.value }, index)}
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Type</InlineFormLabel>
            <Select<DataFrameType | string> // TODO: This is a workaround until we get @grafana/data frontend package sync with backend
              value={frame.meta?.type}
              allowCustomValue={true}
              options={[
                { value: DataFrameType.TimeSeriesWide, label: 'timeseries-wide' },
                { value: DataFrameType.TimeSeriesLong, label: 'timeseries-long' },
                { value: DataFrameType.TimeSeriesMany, label: 'timeseries-many' },
                { value: 'timeseries-multi', label: 'timeseries-multi' },
                { value: 'directory-listing', label: 'directory-listing' },
                { value: 'heatmap-rows', label: 'heatmap-rows' },
                { value: 'heatmap-cells', label: 'heatmap-cells' },
                { value: 'histogram', label: 'histogram' },
                { value: frame.meta?.type, label: frame.meta?.type },
              ].filter((f) => f.value)}
              onChange={(e) => {
                if (e) {
                  onChange({ ...frame, meta: { ...frame.meta, type: e?.value as DataFrameType } }, index);
                  onRunQuery();
                }
              }}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel
              tooltip={`TypeVersion is the version of the Type property. Versions greater than 0.0 correspond to the dataplane contract documentation https://github.com/grafana/grafana-plugin-sdk-go/tree/main/data/contract_docs`}
            >
              Frame version
            </InlineFormLabel>
            <Input
              type="number"
              min={0}
              value={(((frame?.meta as any)?.typeVersion as number[]) || [])[0]}
              onChange={(e) => onFrameVersionChange('major', e.currentTarget.valueAsNumber)}
            />
            <Input
              type="number"
              min={0}
              value={(((frame?.meta as any)?.typeVersion as number[]) || [])[1]}
              onChange={(e) => onFrameVersionChange('minor', e.currentTarget.valueAsNumber)}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Preferred Visualization</InlineFormLabel>
            <Select<PreferredVisualisationType>
              value={frame.meta?.preferredVisualisationType}
              allowCustomValue={true}
              options={[
                { value: 'graph', label: ' Graph' },
                { value: 'table', label: 'Table' },
                { value: 'logs', label: 'Logs' },
                { value: 'trace', label: 'Trace' },
                { value: 'nodeGraph', label: 'Node Graph' },
                { value: frame.meta?.preferredVisualisationType, label: frame.meta?.preferredVisualisationType },
              ]
                .filter((f) => f.value)
                .map((f) => ({ value: f.value as PreferredVisualisationType, label: f.value }))}
              onChange={(e) => {
                onChange({ ...frame, meta: { ...frame.meta, preferredVisualisationType: e.value } }, index);
                onRunQuery();
              }}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Custom</InlineFormLabel>
            <TextArea
              value={custom}
              rows={5}
              onChange={(e) => setCustom(e.currentTarget.value)}
              placeholder={`{ "foo" : "bar" }`}
              onBlur={() => {
                try {
                  const cv = JSON.parse(custom);
                  onChange({ ...frame, meta: { ...frame.meta, custom: cv } }, index);
                } catch (ex) {
                  console.error('unable to update custom');
                }
              }}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Path</InlineFormLabel>
            <Input
              value={frame.meta?.path}
              onChange={(e) => onChange({ ...frame, meta: { ...frame.meta, path: e.currentTarget.value } }, index)}
              onBlur={onRunQuery}
            />
          </div>
          <div className="gf-form">
            <InlineFormLabel>Path Separator</InlineFormLabel>
            <Input
              value={frame.meta?.pathSeparator}
              onChange={(e) =>
                onChange({ ...frame, meta: { ...frame.meta, pathSeparator: e.currentTarget.value } }, index)
              }
              onBlur={onRunQuery}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
