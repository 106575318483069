import { DataQueryRequest, ScopedVars } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { MockQuery, RawFrameGeneratorSource, RawFrameResultState } from './types';

export const interpolateRequest = (request: DataQueryRequest<MockQuery>): DataQueryRequest<MockQuery> => {
  return {
    ...request,
    targets: request.targets.map((t) => interpolateQuery(t), request.scopedVars),
  };
};

export const interpolateQuery = (query: MockQuery, scopedVars: ScopedVars = {}): MockQuery => {
  let newQuery: MockQuery = migrateQuery(query);
  if (newQuery.queryType === 'raw_frame') {
    newQuery.resultState = getTemplateSrv().replace(
      newQuery.resultState || 'success',
      scopedVars
    ) as RawFrameResultState;
    newQuery.generatorSource = getTemplateSrv().replace(
      newQuery.generatorSource || 'frontend',
      scopedVars
    ) as RawFrameGeneratorSource;
  }
  if (newQuery.queryType === 'raw_frame' && newQuery.rawFrameSource === 'inline') {
    newQuery.rawFrameContent = getTemplateSrv().replace(newQuery.rawFrameContent, scopedVars);
  } else if (newQuery.queryType === 'raw_frame' && newQuery.rawFrameSource === 'url') {
    newQuery.url = getTemplateSrv().replace(newQuery.url || '', scopedVars);
  } else if (newQuery.queryType === 'raw_frame' && newQuery.rawFrameSource === 'staticScenario') {
    newQuery.scenario = getTemplateSrv().replace(newQuery.scenario || '', scopedVars);
  }
  return newQuery;
};

export const migrateQuery = (query: MockQuery): MockQuery => {
  let newQuery: MockQuery = { ...query, queryType: query.queryType || 'frames_builder' } as MockQuery;
  if (newQuery.queryType === 'raw_frame') {
    newQuery.rawFrameSource = newQuery.rawFrameSource || 'staticScenario';
    newQuery.resultState = newQuery.resultState || 'success';
    newQuery.generatorSource = newQuery.generatorSource || 'frontend';
    if (newQuery.rawFrameSource === 'inline') {
      newQuery.rawFrameContent = newQuery.rawFrameContent || '[]';
    } else if (newQuery.rawFrameSource === 'staticScenario') {
      newQuery.scenario = newQuery.scenario || 'no-data';
    }
  }
  if (newQuery.queryType === 'json_framer') {
    newQuery.framer = newQuery.framer || 'frontend-grafana-data';
  }
  return newQuery;
};
