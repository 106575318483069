import { DataSourcePlugin } from '@grafana/data';
import { MockDS } from './datasource';
import { MockConfigEditor } from './editors/MockConfigEditor';
import { MockQueryEditor } from './editors/MockQueryEditor';
import { MockVariableEditor } from './editors/MockVariableEditor';
import { MockConfig, MockQuery } from './types';

export const plugin = new DataSourcePlugin<MockDS, MockQuery, MockConfig>(MockDS)
  .setConfigEditor(MockConfigEditor)
  .setQueryEditor(MockQueryEditor)
  .setVariableQueryEditor(MockVariableEditor);
