import React from 'react';
import { QueryEditorProps } from '@grafana/data';
import { MockDS } from '../datasource';
import { QueryTypePicker } from './components/QueryTypePicker';
import { RawFrameEditor } from './components/RawFrameEditor';
import { JSONFramerEditor } from './components/JSONFramerEditor';
import { BuilderEditor } from './components/BuilderEditor';
import { migrateQuery } from '../interpolate';
import { MockQuery, MockConfig } from '../types';

type MockQueryEditorProps = {} & QueryEditorProps<MockDS, MockQuery, MockConfig>;

export const MockQueryEditor = (props: MockQueryEditorProps) => {
  const { onChange, onRunQuery, datasource } = props;
  const query = migrateQuery(props.query);
  return (
    <>
      <QueryTypePicker query={query} onChange={onChange} onRunQuery={onRunQuery} />
      {query.queryType === 'raw_frame' && (
        <RawFrameEditor query={query} onChange={onChange} onRunQuery={onRunQuery} datasource={datasource} />
      )}
      {query.queryType === 'json_framer' && (
        <JSONFramerEditor query={query} onChange={onChange} onRunQuery={onRunQuery} />
      )}
      {query.queryType === 'frames_builder' && (
        <BuilderEditor query={query} onChange={onChange} onRunQuery={onRunQuery} />
      )}
    </>
  );
};
