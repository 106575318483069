import React from 'react';
import { InlineFormLabel, RadioButtonGroup, CodeEditor } from '@grafana/ui';
import { selectors } from './../../selectors';
import type { SelectableValue } from '@grafana/data';
import type { JsonFramerQuery, JsonFramer } from './../../types';

const modes: Array<SelectableValue<JsonFramer>> = [
  { value: 'frontend-grafana-data', label: 'Frontend - Grafana Data toDataFrame' },
  { value: 'backend-framestruct', label: 'Backend - FrameStruct' },
  { value: 'backend-gframer', label: 'Backend - GFramer' },
];

type JSONFramerEditorProps = {
  query: JsonFramerQuery;
  onChange: (query: JsonFramerQuery) => void;
  onRunQuery: () => void;
};

export const JSONFramerEditor = (props: JSONFramerEditorProps) => {
  const { query, onChange, onRunQuery } = props;
  const { jsonFramerEditor: selector } = selectors.QueryEditor;
  return (
    <>
      <div className="gf-form">
        <InlineFormLabel width={12} tooltip={selector.framer.tooltip}>
          {selector.framer.label}
        </InlineFormLabel>
        <RadioButtonGroup<JsonFramer>
          value={query.framer || 'frontend-grafana-data'}
          options={modes}
          onChange={(framer) => {
            onChange({ ...query, framer } as JsonFramerQuery);
            onRunQuery();
          }}
        />
      </div>
      <CodeEditor
        language="json"
        height={'200px'}
        value={query.jsonString || ''}
        onSave={(e) => {
          onChange({ ...query, jsonString: e });
          onRunQuery();
        }}
        onBlur={(e) => {
          onChange({ ...query, jsonString: e });
          onRunQuery();
        }}
      />
    </>
  );
};
